import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  connect () {
    super.connect()
    this.form = this.formTarget
  }

  limpiar () {
    this.form.reset()
    const evento_reset_selects = new CustomEvent("reset-selects")
    window.dispatchEvent(evento_reset_selects)
  }
}
