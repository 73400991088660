import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";


export default class extends Controller {
  static targets = ["input"];
  static values = {
    existentes: Array,
    ruta: String
  }

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
    for (let i = 0; i < this.existentesValue.length; i++) {
      this.dropZone.displayExistingFile(this.existentesValue[i], this.existentesValue[i]['url']);
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = this.inputTarget.name;
      input.id = 'input-imagen-'+this.existentesValue[i]['key'];
      input.value = this.existentesValue[i]['signed_id'];
      insertAfter(input, this.inputTarget);
    }
    this.dropZone.options.maxFiles = this.dropZone.options.maxFiles - this.existentesValue.length;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      var key = file.previewElement.getAttribute('data-signed') || file.key;
      file.previewElement.setAttribute('data-signed', key);
      file.previewElement.id = 'previw-imagen-'+key;
      if(key) {
        file._removeLink = Dropzone.createElement(
          '<a data-controller="confirmation" data-confirmation-object-value="'+file.name+'" data-action="confirmation#confirm" data-confirmation-url-value="'+this.rutaValue+'/delete_image?signed_id='+key+'" rel="nofollow" data-method="delete" href="javascript:void(0)" class="dz-remove" data-dz-remove>Eliminar</a>'
        );
        file.previewElement.appendChild(file._removeLink);
      }
      if(file.type) {
        this.dropZone.options.transformFile.call(this.dropZone, file, (transformedFile) => {
          var new_image = new File([transformedFile], file.upload.filename, {
            name: file.upload.filename,
            filename: file.upload.filename,
            size: transformedFile.size,
            type: transformedFile.type,
          });
          new_image.status = file.status;
          new_image.previewElement = file.previewElement;
          new_image.previewTemplate = file.previewTemplate;
          new_image.upload = file.upload;
          new_image.width = file.width;
          new_image.height = file.height;
          new_image.upload.total = transformedFile.size;
          setTimeout(() => {
            file.accepted && createDirectUploadController(this, new_image).start();
          }, 500);
        });
      }
      else {
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
        }, 500);
      }
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get resizeWidth() {
    return this.data.get("resizeWidth") || 1920;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 50;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        var key = attributes.key;
        this.hiddenInput.value = attributes.signed_id;
        this.hiddenInput.id = 'input-imagen-'+key;
        this.file.previewElement.id = 'previw-imagen-'+key;
        this.file.previewElement.setAttribute('data-signed', key);
        this.file._removeLink = Dropzone.createElement(
          '<a data-controller="confirmation" data-confirmation-object-value="'+this.file.name+'" data-action="confirmation#confirm" data-confirmation-url-value="'+this.source.rutaValue+'/delete_image?signed_id='+key+'" rel="nofollow" data-method="delete" href="javascript:void(0)" class="dz-remove" data-dz-remove>Eliminar</a>'
        );
        this.file.previewElement.appendChild(this.file._removeLink);
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    resizeWidth: controller.resizeWidth,
    resizeQuality: 1,
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: false,
    dictRemoveFile: 'Eliminar',
    dictCancelUpload: 'Cancelar subida',
    dictUploadCanceled: 'Subida cancelada',
    dictInvalidFileType: 'No puede subir ese tipo de archivo',
    dictFileTooBig: "Archivo demasiado grande. Max: "+controller.maxFileSize+"MB.",
    dictMaxFilesExceeded: 'No puedes subir más archivos',
    autoQueue: false,
    capture: true

  });
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}
