import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf'

export default class extends Controller {
  static targets = ['element']
  static values = {
    errorsMessage: String,
    successMessage: String
  }

  connect () {
    super.connect()
    if (this.hasErrorsMessageValue) {
      new Notyf().error(this.errorsMessageValue)
    }
    if (this.hasSuccessMessageValue) {
      new Notyf().success(this.successMessageValue)
    }
  }
}
