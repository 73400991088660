import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf'

export default class extends Controller {
  static targets = ['element']
  static values = {
    ruta: String,
    metodo: String,
    errorsMessage: String,
    successMessage: String
  }

  connect () {
    super.connect()
  }

  update() {
    if (this.hasRutaValue) {
      const tk = document.querySelector('meta[name="csrf-token"]')
      const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
      if(this.elementTarget.value) {
        this.rutaValue = this.rutaValue + this.elementTarget.value
      }
      fetch(this.rutaValue, {
        method: this.hasMetodoValue ? this.metodoValue : 'POST',
        headers: {
          'X-Csrf-Token': token,
          "Accept": "text/vnd.turbo-stream.html"
        }
      }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
    }
  }
}
