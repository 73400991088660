import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']
  static values = {
    ruta: String
  }

  connect () {
    super.connect()
  }

  submit() {
    if (this.hasRutaValue) {
      const tk = document.querySelector('meta[name="csrf-token"]')
      const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
      fetch(this.rutaValue, {
        method: 'POST',
        headers: {
          'X-Csrf-Token': token,
          "Accept": "text/vnd.turbo-stream.html"
        },
        body: new FormData(this.formTarget)
      }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
    }
  }
}
