import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "body", "modal"]

  connect() {
    if(!this.modalTarget.classList.contains('open')) {
      this.modalTarget.classList.add('open')
    }
    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    if(this.modalTarget.classList.contains('open')) {
      this.modalTarget.classList.remove('open')
    }
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close() {
    if(this.modalTarget.classList.contains('open')) {
      this.modalTarget.classList.remove('open')
    }
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.modalTarget.closest("turbo-frame").src = undefined
    this.modalTarget.remove()
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }
}
