import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  static values = {
    on: Array,
    show: Array,
    hide: Array
  }

  connect () {
    super.connect()
    this.form = this.element.closest('form')
    if(this.hasOnValue) {
      this.update()
    }
  }

  update () {
    if(this.hasOnValue) {
      if(this.inputTarget.value.length > 0 && this.onValue.includes('any')) {
        if(this.hasShowValue) {
          for (let i = 0; i < this.showValue.length; i++) {
            document.querySelector(this.showValue[i]).classList.remove('hidden')
          }
        }
        if(this.hasHideValue) {
          for (let i = 0; i < this.hideValue.length; i++) {
            document.querySelector(this.hideValue[i]).classList.add('hidden')
          }
        }
      }
      else
      {
        if(this.onValue.includes(this.inputTarget.value)) {
          if(this.hasShowValue) {
            for (let i = 0; i < this.showValue.length; i++) {
              document.querySelector(this.showValue[i]).classList.remove('hidden')
            }
          }
          if(this.hasHideValue) {
            for (let i = 0; i < this.hideValue.length; i++) {
              document.querySelector(this.hideValue[i]).classList.add('hidden')
            }
          }
        } else
        {
          if(this.hasShowValue) {
            for (let i = 0; i < this.showValue.length; i++) {
              document.querySelector(this.showValue[i]).classList.add('hidden')
            }
          }
          if(this.hasHideValue) {
            for (let i = 0; i < this.hideValue.length; i++) {
              document.querySelector(this.hideValue[i]).classList.remove('hidden')
            }
          }
        }
      }
    }
  }
}
