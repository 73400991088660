import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['alert']

  connect () {
    super.connect()
    var t = this.alertTarget
    setTimeout(function(){ t.remove() }, 11050);
    setTimeout(function(){ t.classList.add('fade-out') }, 10000);
  }
}
