import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import intlTelInputUtils from 'intl-tel-input/build/js/utils.js'

export default class extends Controller {
  static targets = ['input']

  connect () {
    super.connect()
    this.phone = intlTelInput(this.inputTarget, {
        hiddenInput: "phone",
        initialCountry: "ar",
        preferredCountries: ["ar", "uy", "br", "py", "cl", "bo", "ec", "ve", "us" ],
        separateDialCode: true,
        autoPlaceholder: "agressive",
        formatOnDisplay: true
    });
  }

  update () {
   var currentText = this.phone.getNumber(0);
   if (typeof currentText === 'string') {
       this.phone.setNumber(currentText)
   }
  }


  disconnect () {
    this.phone.destroy()
  }
}
