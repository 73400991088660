import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker'

export default class extends Controller {
  static targets = ['input']

  connect () {
    super.connect()
    Datepicker.locales.es = {
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        today: "Hoy",
        monthsTitle: "Meses",
        clear: "Borrar",
        weekStart: 1,
        format: "dd/mm/yyyy"
      }
    this.picker = new Datepicker(this.inputTarget, { buttonClass: 'btn', language: 'es'})

  }

  disconnect () {
    this.picker.destroy()
  }
}
