import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { key: String }

  initialize() {
    console.log('iiiii')
    grecaptcha.render("recaptchaV2", { sitekey: this.keyValue } )
  }
}
