import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf';

export default class extends Controller {
  static values = { object: String, url: String }

  confirm(event) {
    Swal.fire({
      title: 'Eliminar',
      text: 'Vas a eliminar "'+ this.objectValue + '". Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const tk = document.querySelector('meta[name="csrf-token"]')
        const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
        fetch(this.urlValue, {
          method: 'DELETE',
          headers: {
            'X-Csrf-Token': token,
            "Accept": "text/vnd.turbo-stream.html"
          }
        }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
        .then(new Notyf().success( this.objectValue + ' eliminado correctamente.'))
      }
    })
  }
}
