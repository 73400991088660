import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["pwd", "pwd_confirmation"]

  connect() {
    console.log('aca estoy guachin')
    console.log(this.element)
    var input_pass = document.getElementsByClassName("usuario_password")[0];
    var span_pass = document.createElement("span");
    span_pass.innerHTML = '<i class="fa fa-eye-slash"></i>';
    span_pass.setAttribute("data-action", "click->password-toggle#password");
    input_pass.appendChild(span_pass);

    var input_pass_conf = document.getElementsByClassName("usuario_password_confirmation")[0];
    var span_pass_conf = document.createElement("span");
    span_pass_conf.innerHTML = '<i class="fa fa-eye-slash"></i>';
    span_pass_conf.setAttribute("data-action", "click->password-toggle#password_confirmation");
    input_pass_conf.appendChild(span_pass_conf);
  }

  password(e) {
    const icon = e.target;
    if (this.pwdTarget.type === "password") {
      icon.classList.remove('fa-eye-slash');
      icon.classList.add('fa-eye');
      this.pwdTarget.type = "text";
    } else {
      icon.classList.remove('fa-eye');
      icon.classList.add('fa-eye-slash');
      this.pwdTarget.type = "password";
    }
  }

  password_confirmation(e) {
    const icon = e.target;
    if (this.pwd_confirmationTarget.type === "password") {
      icon.classList.remove('fa-eye-slash');
      icon.classList.add('fa-eye');
      this.pwd_confirmationTarget.type = "text";
    } else {
      icon.classList.remove('fa-eye');
      icon.classList.add('fa-eye-slash');
      this.pwd_confirmationTarget.type = "password";
    }
  }
}
