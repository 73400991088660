import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = ['select']
  static values = {
    limit: Number,
    placeholder: String,
    searchText: String,
    searchingText: String,
    reflex: String,
    showSearch: Boolean,
    searchPlaceholder: String,
    update: String
  }

  connect () {
    super.connect()
    const closeOnSelect = this.single
    const allowDeselect = !this.selectTarget.required
    this.form = this.element.closest('form')
    this.select = new SlimSelect({
      select: this.selectTarget,
      closeOnSelect,
      allowDeselect,
      limit: this.limitValue,
      showSearch: this.hasShowSearchValue ? this.showSearchValue : true,
      placeholder: this.hasPlaceholderValue
        ? this.placeholderValue
        : 'Seleccionar',
      searchText: this.hasSearchTextValue ? this.searchTextValue : 'No hay resultados',
      searchPlaceholder: this.hasSearchPlaceholderValue ? this.searchPlaceholderValue : 'Buscar',
      searchingText: this.hasSearchingTextValue
        ? this.searchingTextValue
        : 'Buscando...',
      onChange: this.onChange
    })
  }

  search = (search, callback) =>
    this.stimulate(this.reflexValue, search).then(({ payload }) =>
      callback(payload)
    )

  onChange = () => {
    if (this.hasUpdateValue) {
      const tk = document.querySelector('meta[name="csrf-token"]')
      const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
      fetch(this.updateValue, {
        method: 'POST',
        headers: {
          'X-Csrf-Token': token,
          "Accept": "text/vnd.turbo-stream.html"
        },
        body: new FormData(this.form)
      }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
    }
    if (!this.select.data.searchValue) return
    if (this.select.selected() === undefined)
      this.stimulate(this.reflexValue, '')
  }

  get single () {
    return !this.selectTarget.multiple
  }
  get multi () {
    return this.selectTarget.multiple
  }

  reconnect () {
    this.select.destroy()
    this.connect()
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }
}
