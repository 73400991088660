import { Controller } from "@hotwired/stimulus"
import Accordion from 'accordion/src/accordion'

export default class extends Controller {
  static targets = ['element']

  connect () {
    super.connect()
    this.accordion = new Accordion(this.elementTarget)
  }

  disconnect () {
    this.accordion = undefined
  }
}
